.borderShare{
    background: #121866;
    border: 1px solid #20BCFC;
    position: relative;
    box-shadow: #20BCFC 0px 0px 48px -20px inset;
}
.borderShare > .pos-abs{
    width: 20px;
    height: 20px;
    position: absolute;
}
.borderShare > div:first-child{
    top: -2.5px;
    left: -2.5px;
    border-left: 3px solid #20bcfc ;
    border-top: 3px solid #20bcfc;
}
.borderShare >div:nth-child(2){
    top: -2.5px;
    right: -2.5px;
    border-right: 3px solid #20bcfc;
    border-top: 3px solid #20bcfc;
}
.borderShare >div:nth-child(3){
    bottom: -2.5px;
    left: -2.5px;
    border-bottom: 3px solid #20bcfc;
    border-left: 3px solid #20bcfc;
}
.borderShare >div:nth-child(4){
    bottom: -2.5px;
    right: -2.5px;
    border-bottom: 3px solid #20bcfc;
    border-right: 3px solid #20bcfc;
}
.borderShare .borderTitle{
    font-size: 18px;
    line-height: 46px;
    background: url(../../../../assets/images/public/titleBg.png) center no-repeat;
    text-align: center;
    margin-top: -2px;
    padding-right: 20px;
}