
.loginMain .loginBanner{
    height: 70vh;
    background-image: url('../../assets/images/login/login-bg.png');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
}
.loginMain .official{
    height: 30vh;
}
.loginMain .content{
    overflow: hidden;
    width: 1200px;
    margin: 0 auto;
}
.loginMain .head{
    overflow: hidden;
    height: 40px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    color: #ffffff;
}
.loginMain .head img{
    width: auto;
    height: 100%;
}
.loginMain .head ul{
    width: 150px;
    display: flex;
    justify-content: space-between;
}
.loginMain .official .content{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loginMain .official .content div p{
    font: 14px;
    color: #808080;
    line-height: 14px;
}
.loginMain .official .content div p:nth-of-type(1){
    font-size: 36px;
    color: #1a1a1a;
    line-height: 38px;
}
.loginMain .official .content div p:nth-of-type(1) img{
    vertical-align: middle;
    margin: -5px 10px 0 0;
}
.loginMain .official .content div p:nth-of-type(2){
    margin-top: 40px;
}
.loginMain .official .content div p:nth-of-type(3){
    margin-top: 12px;
}
.loginMain .official .content div p:nth-of-type(3) a{
    color: #808080;
}
.loginMain .official .content div p:nth-of-type(3) a:hover{
    text-decoration:underline;
}
.loginMain .official .content ul{
    width: 290px;
    display: flex;
    justify-content: space-between;
    margin: 0;
}
.loginMain .official .content ul img{
    width: 130px;
    height: 130px;
}
.loginMain .official .content ul p{
    margin-top: 8px;
    text-align: center;
    font-size: 14px;
    color: #1a1a1a;
}
.signModule{
    height: calc(70vh - 70px);
    display: flex;
    justify-self: start;
    flex-direction: row-reverse;
    align-items: center;
}