.responsive-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.responsive-list .responsive-item {
  width: calc(25% - 15px);
  min-height: 270px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.responsive-list .responsive-item:nth-child(4n) {
  margin-right: 0px;
}

/* .passignment-charts-container .responsive-list .responsive-item {
  width: calc(25% - 15px);
  min-width: 290px;
}

@media screen and (max-width: 1450px) {
  .passignment-charts-container .responsive-list .responsive-item {
    margin-right: 20px;
  }
} */