@media screen and (min-width:1441px) and (max-width:1919px){
  .classSituationBox,
  .realTimeBox,
  .analysisBox,
  .dataScreeningBox{
    padding: 0 25px;
  }
  .courseNewsBox .curriculumImg{
    background: url(./assets/images/cloudChart/curriculumBg1.png) center no-repeat;
  }
  .courseNewsBox .curriculumImg .leftTopClass{
    top: 18px;
    left: 58px;
  }
  .courseNewsBox .curriculumImg .rightTopClass{
    right: 58px;
    top: 22px;
  }
  .courseNewsBox .curriculumImg .leftBottomClass{
    left: 58px;
    bottom: 32px;
  }
  .courseNewsBox .curriculumImg .rightBottomClass{
    right: 58px;
    bottom: 32px;
  }
  .dataScreeningBox .dataInfoBox .info .sums{
    font-size: 24px;
    line-height: 30px;
  }
  .realTimeBox .studyInfo{
    margin: 10px 0;
  }
  .realTimeBox .progressBox .progressItem .ant-progress-line{
    width: 65%;
  }
}
@media screen and (max-width:1440px){
  .classSituationBox,
  .realTimeBox,
  .analysisBox,
  .dataScreeningBox{
    padding: 0 20px;
  }
  .courseNewsBox .curriculumImg{
    background-size: 90% 90%;
  }
  .courseNewsBox .curriculumImg .leftTopClass{
    top: 8px;
    left: 30px;
  }
  .courseNewsBox .curriculumImg .rightTopClass{
    right: 34px;
    top: 12px;
  }
  .courseNewsBox .curriculumImg .leftBottomClass{
    left: 30px;
    bottom: 23px;
  }
  .courseNewsBox .curriculumImg .rightBottomClass{
    right: 34px;
    bottom: 21px;
  }
  .dataScreeningBox .dataInfoBox .info .sums{
    font-size: 24px;
  }
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem{
    margin-bottom: 7px;
  }
  .classSituationBox .situationContent .leftSituation .studentRankings{
    margin: 20px 0 !important;
  }
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem .studentName{
    width: 70px;
  }
  .classSituationBox .df{
    min-height: 200px;
  }
  .analysisECharts .blueWhaleEChart .list{
    padding-top: 0;
  }
  .realTimeBox .progressBox .progressItem{
    margin-bottom: 4px;
  }
  .realTimeBox .studyInfo{
    margin: 10px 0;
    font-size: 12px;
  }
  .dynamicECharts .blueWhaleEChart .list p{
    line-height: 22px;
  }
}
@media screen and (max-width:1366px){
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem{
    min-width: 115px;
  }
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem .studentName{
    width: 55px;
    font-size: 12px;
    margin: 0 5px;
  }
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem .portrait{
    width: 16px !important;
    height: 16px !important;
  }
  .classSituationBox .situationContent .leftSituation .detaultClass{
    padding: 0 5px;
  }
}

@media screen and (min-height:1080px){
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem{
    margin-bottom: 16px;
  }
  .blueWhaleBox .ant-progress-success-bg, .ant-progress-bg{
    height: 10px !important;
  }
  .realTimeBox .progressBox .progressItem{
    margin-bottom: 10px;
  }
  .realTimeBox .studyInfo{
    margin: 15px 0;
    font-size: 14px;
  }
  .classSituationBox .situationContent .leftSituation .studentRankings,
  .classSituationBox .situationContent .leftSituation .leftScreen{
    margin-top: 30px !important;
  }
  .courseNewsBox .curriculumImg .leftTopClass{
    top: 18px;
    left: 72px;
  }
  .courseNewsBox .curriculumImg .rightTopClass{
    right: 72px;
    top: 22px;
  }
  .courseNewsBox .curriculumImg .leftBottomClass{
    left: 72px;
    bottom: 34px;
  }
  .courseNewsBox .curriculumImg .rightBottomClass{
    right: 72px;
    bottom: 34px;
  }
}
@media screen and (max-height:800px){
  .blueWhaleBox .pathContent{
    font-size: 24px;
  }
  .blueWhaleBox .titleBox .times{
    font-size: 14px;
    line-height: 35px;
  }
  .blueWhaleBox .titleBox{
    height: 40px;
    line-height: 45px;
  }
  .dataScreeningBox .dataInfoBox .info{
    /* height: 50px !important;
    min-height: 52px !important; */
    margin-bottom: 15px;
    line-height: 20px;
  }
  .dataScreeningBox .dataInfoBox .info .sums{
    font-size: 20px;
  }
  .dataScreeningBox .dataInfoBox .info:nth-child(7),
  .dataScreeningBox .dataInfoBox .info:nth-child(8){
    margin-bottom: 0;
  }
  .courseNewsBox .curriculumImg{
    background: url(./assets/images/cloudChart/curriculumBg1.png) center no-repeat;
    background-size: 90% 90%;
    margin-top: 0px;
  }
  .courseNewsBox .curriculumImg .leftTopClass{
    top: 2px;
    left: 30px;
  }
  .courseNewsBox .curriculumImg .rightTopClass{
    right: 30px;
    top: 4px;
  }
  .courseNewsBox .curriculumImg .leftBottomClass{
    left: 30px;
    bottom: 17px;
  }
  .courseNewsBox .curriculumImg .rightBottomClass{
    right: 30px;
    bottom: 17px;
  }
  .realTimeBox .studyInfo{
    margin: 5px 0;
  }
  .realTimeBox .progressBox .progressItem{
    margin-bottom: 0;
  }
  .borderShare .borderTitle{
    background-size: 44%;
    margin-top: -8px;
    font-size: 16px;
  }
  .classSituationBox .borderTitle,
  .analysisBox .borderTitle{
    background-size: 30%;
    margin-top: -7px;
  }
  .dataScreeningBox .screenBox{
    margin-top: 0;
  }
  .analysisBox .screenBox,
  .classSituationBox .situationContent .leftSituation .leftScreen{
    margin-top: 8px;
  }
  .dynamicECharts .title{
    padding-top: 0;
    margin-bottom: 8px;
  }
  .dataScreeningBox .dataInfoBox{
    margin-top: 10px;
  }
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem{
    margin-bottom: 4px;
  }
  .classSituationBox .situationContent .progressBox .progressItem{
    max-height: 80px;
  }
  .classSituationBox .situationContent .rightSituation .progressBox{
    max-height: 90px;
  }
  .courseNewsBox .slick-slider .slick-list{
    height: 100px !important;
  }
  .dataScreeningBox .dataInfoBox .info .slash{
    font-size: 18px;
    line-height: 16px;
    height: 20px;
  }
  .dataScreeningBox .dataInfoBox .info .textTitle{
    line-height: normal;
  }
}
@media screen and (max-height:700px){
  .dataScreeningBox .dataInfoBox .info{
    height: 42px !important;
    min-height: 42px !important;
    margin-bottom: 12px;
  }
  .dataScreeningBox .dataInfoBox .info .sums{
    font-size: 17px;
    line-height: normal;
    margin-bottom: 0px;
  }
  .dataScreeningBox .dataInfoBox .info .slash{
    font-size: 12px;
    line-height: normal;
    height: auto;
    padding-top: 0;
  }
  .dataScreeningBox .dataInfoBox .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .realTimeBox .progressBox .progressItem{
    font-size: 13px;
    height: 17px;
  }
  .classSituationBox .situationContent .leftSituation .studentRankings{
    margin: 10px 0 !important;
  }
  .classSituationBox .situationContent .leftSituation .ankingsBox .ankingsItem{
    font-size: 13px;
    height: 26px;
  }
  .analysisECharts .blueWhaleEChart .list{
    padding-top: 0px;
  }
  .analysisBox .screenBox, .classSituationBox .situationContent .leftSituation .leftScreen{
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .blueWhaleBox{
    overflow: auto;
    padding: 0 20px;
  }
  .classSituationBox,
  .realTimeBox,
  .analysisBox,
  .dataScreeningBox{
    padding: 0 15px;
  }
  .blueWhaleBox .dynamicBox,
  .blueWhaleBox .situationBox,
  .classSituationBox .situationContent,
  .analysisEcharts{
    flex-wrap: wrap;
  }
  .blueWhaleBox .dynamicBox >div,
  .blueWhaleBox .situationBox > div,
  .classSituationBox .situationContent >div,
  .classSituationBox .situationContent >.rightSituation{
    width: 100%;
    height: auto !important;
    margin-bottom: 30px;
  }
  .blueWhaleBox .dynamicBox >div{
    padding-bottom: 20px;
  }
  .classSituationBox .situationContent >div{
    margin-bottom: 0;
  }
  .analysisECharts,
  .analysisCurveECharts{
    width: 100%;
  }
  .blueWhaleBox .titleBox{
    text-align: center;
    line-height: 25px;
    /* position: fixed;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%); */
  }
  .blueWhaleBox .titleBox .times{
    display: none;
  }
  .blueWhaleBox .pathContent{
    font-size: 16px;
    margin-right: 0;
    margin: 0 auto;
  }
  .blueWhaleBox .progressBox,
  .analysisECharts .blueWhaleEChart{
    height: auto !important;
  }
  .blueWhaleBox .dynamicBox{
    margin-bottom: 0;
    /* margin-top: 70px; */
  }
  .analysisECharts .blueWhaleEChart{
    margin-bottom: 20px;
  }
  .classSituationBox .df{
    width: 100% !important;
    min-height: 100px;
  }
  .realTimeBox .progressBox .progressItem > span:first-child{
    font-size: 12px;
  }
  .realTimeBox .progressBox .progressItem > span:first-child{
    min-width: 125px;
  }
  .realTimeBox .progressBox .progressItem > span{
    /* min-width: 45px; */
    font-size: 10px;
  }
  .dynamicECharts .blueWhaleEChart .list p{
    width: 67px;
  }
  .analysisECharts .blueWhaleEChart .container{
    height: 150px !important;
  }
  .realTimeBox .progressBox .progressItem .ant-progress-line{
    width: 50%;
    margin: 0 10px 0 10px;
  }
}

