.bi_chart {
  width: 100%;
  height: 100%;
  background: #041081;
  overflow: hidden;
  color: #e6e6e6;
}

.bi_chart h1 {
  text-align: center;
  color: #F2F2F2;
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  height: 70px;
  letter-spacing: 3px;
  background: url("../../../assets/images/cloudChart/bi/header.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-shadow: 0px 2px 0px #03B7BE;
  font-family: PingFang SC, PingFang SC !important;
  letter-spacing: 3px;
  padding-bottom: 4px;
}

.bi_chart h1 .logo {
  margin-right: 4px;
  vertical-align: middle;
  height: 34px;
  margin-top: 3px;
}

.bi_chart .Entrepreneurship_Map h2 {
  font-size: 16px;
  flex-direction: row;
  color: #F2F2F2;
}

.bi_chart .Entrepreneurship_Map .sku {
  font-size: 16px;
}

.bi_chart_body {
  display: flex;
  width: 100%;
  height: calc(100% - 130px);
  padding: 1.2rem 1.5vw 2rem;
}

.bi_chart_body_left {
  width: 26%;
}

.bi_chart_body_center {
  width: 48%;
  margin: 0 20px;
}

.bi_chart_body_right {
  width: 26%;
}

.bi_chartBox {
  background-image: url("../../../assets/images/cloudChart/bi/box_1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 20px;
  height: 32%;
  padding: 0.8rem 2rem 0;
  position: relative;
}

.bi_chartInfo {
  height: 32%;
  margin-bottom: 20px;
}


.bi_chartBox h4 {
  font-size: 18px;
  color: #00E6F6;
  line-height: normal;
  width: 60%;
  height: 26px;
  padding-left: 1.8rem;
  padding-bottom: 1rem;
  background-image: url("../../../assets/images/cloudChart/bi/title.png");
  background-repeat: no-repeat;
  background-size: 175px 26px;
  white-space: nowrap;
  transform: translateX(-12px);
}

.bi_chart .subtitle {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 12px;
  height: 26px;
  line-height: 2;
}

.bi_chartBox.daySalesData {
  background-image: url("../../../assets/images/cloudChart/bi/box_2.png");
}

.bi_chartBox.daySalesData h4 {
  width: 254px;
}

.bi_chart>.SchoolSwiper .SchoolSwiper_logo {
  height: auto;
}

.daySalesData .SchoolSwiper_logo {
  height: 22px;
}

.bi_chart .daySalesData h4 {
  width: 178px;
}

.bi_chart .daySalesData .SchoolSwiper {
  position: absolute;
  top: 15px;
  left: 200px;
  width: 32vw;
  background: #0027B2;
  padding: 0;
  height: 22px;
}

.bi_chart .daySalesData .SchoolSwiper .swiper-slide {
  padding: 0;
  height: 22px;
  background-color: transparent;
}

.bi_chart_echart {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 1rem;
}

.bi_chartInfo ul {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bi_chartInfo li {
  width: calc(50% - 7.5px);
  height: calc(33% - 10px);
  background: #2c0a36;
  box-shadow: inset 2px 2px 15px 1px rgba(235, 35, 89, 0.3);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  margin-bottom: 15px;
}

.bi_chartInfo li div {
  font-size: 26px;
  line-height: 30px;
  color: #f90d4d;
}

.SchoolSwiper {
  padding: 0 1.5vw;
  height: 50px;
}

.SchoolSwiper_logo {
  height: 35px;
}

.bi_chart .SchoolSwiper .swiper-slide {
  width: auto;
  padding: 0 16px;
  height: 50px;
  border-radius: 4px;
  background-color: #BECCFE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bi_chart .Entrepreneurship_Group_item {
  color: #F2F2F2;
}

.bi_chart .College-table-td {
  color: #F2F2F2;
}

.bi_chart .College-table-th .th {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: #F2F2F2;
}

.bi_chart .College-table-td:nth-last-child(1) {
  text-align: right;
}

.bi_chart .Entrepreneurship_Group_item .value {
  padding-right: 0px;
}

.bi_chart .issue .Entrepreneurship_Group_item>div {
  background-color: #0027B2;
}


.bi_chart .Entrepreneurship_Map h2 .sums {
  background: #0446F7;
  width: 42px;
  height: 58px;
  font-family: DINPro, DINPro !important;
  font-weight: 500;
  font-size: 58px;
  margin-right: 8px;
  line-height: 1;
  color: #F2F2F2;
}

.bi_chart .ljScreenMap {
  top: 60px;
  width: 66%;
  left: 2%;
}

.bi_chart .salesVolume .bi_chart_echart {
  top: 30px;
}

.bi_chart .daySalesData .bi_chart_echart {
  top: 30px;
}


.bi_chart .bi_echart h5 {
  font-size: 14px;
  color: #F2F2F2;
  position: absolute;
  padding-left: 7px;
  top: 20px;
}

.bi_chart .bi_echart.box_1 {
  width: 90%;
  height: 50%;
  position: absolute;
  top: 20px;
  z-index: 1;
}

.bi_chart .bi_echart.box_2 {
  height: 48%;
  position: absolute;
  top: 45%;
}

.bi_chart .bi_echart.box_2 h5 {
  top: 0;
  position: relative;
  margin-bottom: 5px;
}

.bi_chart .bi_echart h5::before {
  content: ' ';
  position: absolute;
  left: 0px;
  top: 5px;
  width: 2px;
  height: 15px;
  background: #23C2EB;
}

.bi_chart .ant-table {
  background: transparent;
  font-size: 14px;
}

.bi_chart .SMap-table {
  bottom: 60px;
  top: auto;
  right: 3%;
}

.bi_chart .ant-table-tbody>tr>td {
  background: transparent;
  color: #F2F2F2;
  border-bottom: 0;
  padding: 2px 5px 2px 0;
  white-space: nowrap;
  text-align: left;
}

.bi_chart .ant-table-tbody>tr:hover td {
  background: transparent;
}


.bi_chart .SMap-table .ant-table-thead>tr>th {
  background: rgba(0, 0, 0, 0);
  color: #F2F2F2;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0px 2px;
  padding-bottom: 5px;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
}

.bi_chart .Entrepreneurship_Group_item .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.bi_chart .Entrepreneurship_Group_item .logo img {
  width: 100%;
}

.bi_chart .Entrepreneurship_Group_item .name {
  display: flex;
  align-items: center;
}

.bi_chart .progress {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1.4;
}

.bi_chart .progress .label {
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transform: scale(.9);
  color: #F2F2F2;
}

.bi_chart .progress .line {
  width: 255px;
  height: 8px;
  border-radius: 4px;
  background: #2850DE;
  position: relative;
}

.bi_chart .progress .line div {
  position: absolute;
  height: 8px;
  z-index: 1;
  border-radius: 4px;
  transition: all 0.1;
}

.bi_chart .progress .num {
  width: 50px;
  text-align: right;
  color: #F2F2F2;
}

.daySalesData .Competition_echart {
  top: 45px;
}

@media screen and (max-width: 1700px) {
  .bi_chart .progress {
    line-height: 1.3;
  }

  .bi_chart .progress .label {
    width: 100px;
    transform: scale(.9);
  }

  .bi_chart .progress .line {
    width: 180px;
  }

  .bi_chart .bi_echart.box_2 {
    overflow: hidden;
  }

  .bi_chart .SMap-table .ant-table-tbody>tr>td {
    padding: 3px 2px;
    font-size: 12px;
  }

  .bi_chart .SMap-table .ant-table-thead>tr>th {
    font-size: 12px;
  }

  .bi_chart .Entrepreneurship_Map_body {
    height: calc(100% - 4rem);
  }
}

@media screen and (max-width: 1500px) {
  /* .bi_chart .title_map {
    display: block;
    width: 90px;
  } */

  .bi_chart .daySalesData .SchoolSwiper {
    width: 29vw;
  }

  .bi_chart .progress .label {
    width: 80px;
  }

  .bi_chart .progress .line {
    width: 170px;
  }

  .bi_chart .progress .num {
    width: 50px;
  }
}

@media screen and (max-height: 800px) {
  .bi_chart h1 {
    height: 60px;
    line-height: 1;
    font-size: 36px;
  }

  .bi_chart h1 .logo {
    height: 34px;
    font-size: 36px;
  }

  .bi_chart .bi_echart.box_2 {
    height: 47%;
  }

  .bi_chart_body {
    height: calc(100% - 120px);
    padding: 1rem 1.5vw 1.8rem;
  }

  .bi_chart .ljScreenMap {
    transform: scale(.95);
    top: 50px;
  }

  .bi_chart_body_left,
  .bi_chart_body_right {
    width: 27.5%;
  }

  .bi_chart_body_center {
    width: 45%;
  }

  .bi_chart .daySalesData .SchoolSwiper {
    width: 26vw;
  }

  .bi_chart .SMap-table {
    bottom: 30px;
  }


  .bi_chart .Entrepreneurship_Group_list {
    margin-top: 0;
  }

  .bi_chart .Entrepreneurship_Group_item {
    padding: 2px 0;
  }


  .Entrepreneurship_Group_item .value .jz {
    width: 1.5rem;
    height: 1.5rem;
  }

  .bi_chart .College-table-td {
    color: #F2F2F2;
    padding: 2px 0;
    font-size: 12px;
    line-height: 1.2;
  }
}


@media screen and (min-height: 1080px) {
  .bi_chart .progress {
    line-height: 1.5;
  }

  .bi_chart .progress .label {
    transform: scale(1);
  }

  .bi_chart .bi_echart.box_2 {
    top: 50%;
  }

  .bi_chart .SMap-table {
    bottom: 68px;
  }

  .bi_chart .College-table-td {
    font-size: 14px;
    padding: 6.5px 0;
  }

  .bi_chart .ljScreenMap {
    width: 70%;
    left: -1%;
    /* top: -30px; */
  }
}