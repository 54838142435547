.ContentMain {
    min-height: 100vh;
    /* min-width: 1200px; */
}

.ContentMain .ant-menu-item,
.ContentMain .ant-menu-submenu-title {
    display: flex;
    align-items: center;
}

.ContentMain .teacherMenu .ant-menu-submenu:last-child .ant-menu-item:last-child {
    margin-bottom: 30px;
}

.ContentMain .ant-menu-inline-collapsed .ant-menu-item,
.ContentMain .ant-menu-inline-collapsed .ant-menu-submenu-title {
    padding-left: 24px;
}

.ContentMain .ant-menu-item span,
.ContentMain .ant-menu-submenu-title span {
    cursor: pointer;
}

.ContentMain .ant-menu-submenu-title span.icon,
.ContentMain .ant-menu-item span.icon {
    min-width: 24px;
    min-height: 24px;
    margin-right: 6px;
}

.ContentMain .ant-menu-item span.iconteaching-center {
    background: url(../../assets/images/sider-menu-icon/control.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-item.ant-menu-item-selected span.iconteaching-center {
    background: url(../../assets/images/sider-menu-icon/control-selected.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu .ant-menu-submenu-title span.iconstudent-management {
    background: url(../../assets/images/sider-menu-icon/student-manage.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title span.iconstudent-management {
    background: url(../../assets/images/sider-menu-icon/student-manage-selected.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu .ant-menu-submenu-title span.icontraining-tracking {
    background: url(../../assets/images/sider-menu-icon/training-manage.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu .ant-menu-submenu-title span.iconjh-tracking {
    background: url(../../assets/images/sider-menu-icon/teacher-manage.svg) no-repeat;
    background-size: 104%;
}

.ContentMain .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title span.iconjh-tracking {
    background: url(../../assets/images/sider-menu-icon/teacher-manage-selected.svg) no-repeat;
    background-size: 104%;
}

.ContentMain .ant-menu-submenu .ant-menu-submenu-title span.iconclass-tracking {
    background: url(../../assets/images/sider-menu-icon/class-manage.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title span.iconclass-tracking {
    background: url(../../assets/images/sider-menu-icon/class-manage-selected.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title span.icontraining-tracking {
    background: url(../../assets/images/sider-menu-icon/training-manage-selected.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu span.icon-kechengguanli {
    background: url(../../assets/images/sider-menu-icon/course-manage.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title span.icon-kechengguanli {
    background: url(../../assets/images/sider-menu-icon/course-manage-selected.svg) no-repeat;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu .ant-menu-submenu-title span.iconclass-practice {
    background: url(../../assets/images/sider-menu-icon/class-practice.svg) no-repeat;
    color: red;
    background-size: 100%;
}

.ContentMain .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title span.iconclass-practice {
    background: url(../../assets/images/sider-menu-icon/class-practice-selected.svg) no-repeat;
    background-size: 100%;
}




/* .ContentMain .ant-menu-vertical.ant-menu{
    width: 100px !important;
}
.ContentMain .ant-menu-inline-collapsed.ant-menu,
.ContentMain .ant-menu-vertical.ant-menu{
    width: 100px !important;
} */

.ContentMain .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #28455B;
}

.ContentMain .ant-layout-sider::-webkit-scrollbar {
    width: 6px;
}

.ContentMain .ant-layout-sider::-webkit-scrollbar {
    width: 6px;
}

.ContentMain .ant-layout-sider::-webkit-scrollbar-thumb {
    background: #aaa;
}

.ContentMain .ant-layout-sider::-webkit-scrollbar-track {
    background-color: #ddd;
}

/* 导航的宽度 */
/* @media (min-width: 1900px){
    .ContentMain .ant-layout-sider{
        min-width: 260px !important;
        max-width: 260px !important;
        background-color: #28455B;
    }
    .ContentMain .ant-layout-sider.ant-layout-sider-collapsed{
        min-width: 100px !important;
        max-width: 100px !important;
    }
    .ContentMain .ant-menu-inline-collapsed.ant-menu, .ContentMain .ant-menu-vertical.ant-menu {
        width: 100px !important;
    }

    .ContentMain .ant-menu-inline-collapsed.ant-menu{
        padding-left: 12px !important;
    }
    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
        right: 24px;
    }
}

@media (max-width: 1900px){
    .ContentMain .ant-layout-sider {
        min-width: 180px !important;
        max-width: 180px !important;
        background-color: #28455B;
    }
    .ContentMain .ant-layout-sider.ant-layout-sider-collapsed{
        min-width: 100px !important;
        max-width: 100px !important;
    }
    .ContentMain .ant-menu-inline-collapsed.ant-menu, .ContentMain .ant-menu-vertical.ant-menu {
        width: 100px !important;
    }
    
    .ContentMain .ant-menu-item, .ContentMain .ant-menu-submenu-title {
        padding-left: 12px !important;
    }
    .ContentMain .ant-menu-inline-collapsed.ant-menu{
        padding-left: 24px !important;
    }

    .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
        right: 12px;
    }

} */




.ContentMain .teacherMenu .ant-menu-submenu-title,
.ContentMain .teacherMenu .ant-menu-item {
    padding: 0 24px;
    display: flex;
    justify-content: center;
}

.ContentMain .teacherMenu .icon {
    position: absolute;
    top: 7px;
    left: 40px;
    z-index: 1;
}

.ContentMain .teacherMenu .menu-title-text {
    display: inline-block;
    height: 100%;
    opacity: 1;
    transition: opacity .3s;
}


#root .ContentMain .ant-menu-item-selected {
    background-color: transparent;
}

/* #root .ContentMain .ant-menu-inline-collapsed .ant-menu-item-selected {
    background-color: #EDF6F7;
}  */


.ContentMain .teacherMenu .menu-item-content {
    display: inline-block;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    right: 0;
    left: 35px;
}

/* .ContentMain  .teacherMenu > .ant-menu-item > .menu-item-content, 
.ContentMain  .teacherMenu .ant-menu-sub .menu-item-content */


.ContentMain .teacherMenu.ant-menu-inline-collapsed .ant-menu-submenu-selected>div>.menu-item-content {
    background-color: #EDF6F7;
    border-bottom-left-radius: 23px;
    border-top-left-radius: 23px;
}

.ContentMain .ant-menu-item-selected .menu-item-content {
    background-color: #EDF6F7;
    border-bottom-left-radius: 23px;
    border-top-left-radius: 23px;
}


.ContentMain .teacherMenu.ant-menu-inline-collapsed .menu-title-text {
    opacity: 0;
}


.ContentMain .ant-menu-submenu .ant-menu .ant-menu-item {
    padding: 0px 24px !important;
}

/* .ContentMain .teacherMenu > li:nth-last-child(1){
    height: 120px;
} */

/*  */


/* 全屏 */
@media (min-width: 1900px) {
    .ContentMain .ant-layout-sider {
        min-width: 260px !important;
        max-width: 260px !important;
        background-color: #28455B;
    }

    .ContentMain .ant-layout-sider.ant-layout-sider-collapsed {
        min-width: 100px !important;
        max-width: 100px !important;
    }

    .ContentMain .ant-menu-inline-collapsed.ant-menu,
    .ContentMain .ant-menu-vertical.ant-menu {
        width: 100px !important;
    }



    .ContentMain .teacherMenu .icon {
        left: 21px;
    }

    .ContentMain .teacherMenu.ant-menu-inline-collapsed .icon {
        left: 14px;
    }

    /*  */
    .ContentMain .teacherMenu.ant-menu-inline-collapsed .menu-item-content {
        padding-left: 40px;
        left: 22px
    }

    /* 一级菜单右侧箭头位置 */
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
        right: 39px;
    }

    /* 二级菜单位置 */
    .ContentMain .teacherMenu .ant-menu-sub .menu-item-content {
        padding-left: 36px;
        left: 60px;
    }
}


.ant-menu-submenu ul,
.ant-tooltip .ant-tooltip-inner {
    width: auto;
    background-color: #28455B !important;
    padding: 6px 10px;
}

.ant-tooltip {
    width: auto;
}

.ant-tooltip p {
    margin: 0;
}

/* .ant-menu-submenu ul .ant-menu-item-selected{
    color: #333;
    background-color: #EDF6F7 !important;
}
.ant-menu-submenu ul .ant-menu-item-selected:hover{
    color: #333;
    background-color: #EDF6F7 !important;
} */
.ant-menu-submenu .ant-menu-item span {
    font-size: 14px !important;
}

.ContentMain .ant-menu-item-selected .menu-title-text {
    color: #333;
}

.ContentMain .ant-layout-sider {
    background-color: #28455B !important;
}

.ContentMain .ant-menu-vertical .ant-menu-submenu {
    position: relative;
    overflow: visible;
}

.ContentMain .ant-layout-sider .ant-menu-item {
    color: rgba(255, 255, 255, 0.65);
    height: 46px;
    line-height: 46px;
    margin: 0;
    position: relative;
    overflow: visible;
    transition: none;
    border-bottom-left-radius: 23px;
    border-top-left-radius: 23px;
}

.ContentMain .ant-layout-sider .ant-menu-item-active {
    color: #fff;
}

.ContentMain .ant-layout-sider .teacherMenu>li {
    color: #fff;
    position: relative;
}

.ContentMain .ant-layout-sider .teacherMenu>li .ant-menu-submenu-arrow {
    opacity: 1;
}


.ContentMain .ant-menu-vertical .ant-menu-submenu.ant-menu-submenu-selected .top-mask,
.ContentMain .ant-layout-sider .ant-menu-item.ant-menu-item-selected .top-mask {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-100%);
    height: 30px;
    width: 30px;
    background-color: #EDF6F7;
    display: flex;
}

.ContentMain .ant-menu-vertical .ant-menu-submenu.ant-menu-submenu-selected .top-mask::before,
.ContentMain .ant-layout-sider .ant-menu-item.ant-menu-item-selected .top-mask::before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: #28455B;
    border-bottom-right-radius: 80%;
}

.ContentMain .ant-menu-vertical .ant-menu-submenu.ant-menu-submenu-selected .bottom-mask,
.ContentMain .ant-layout-sider .ant-menu-item.ant-menu-item-selected .bottom-mask {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    height: 30px;
    width: 30px;
    background-color: #EDF6F7;
    display: flex;
}

.ContentMain .ant-menu-vertical .ant-menu-submenu.ant-menu-submenu-selected .bottom-mask::before,
.ContentMain .ant-layout-sider .ant-menu-item.ant-menu-item-selected .bottom-mask::before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: #28455B;
    border-top-right-radius: 80%;
}

.ContentMain .ant-menu-item.ant-menu-item-selected .iconfont {
    color: #28BBAC !important;
}

.ContentMain .ant-menu-submenu.ant-menu-submenu-vertical .ant-menu-submenu-title {
    color: #fff;
    height: 46px;
    line-height: 46px;
    margin: 1px 0;
    overflow: visible;
    transition: none;
    border-bottom-left-radius: 23px;
    border-top-left-radius: 23px;
}

/* .ContentMain .ant-menu-submenu .ant-menu{
    padding-left: 20px;
} */
/* .ContentMain .ant-menu-submenu.ant-menu-submenu-selected.ant-menu-submenu-vertical .ant-menu-submenu-title{
    background-color: #EDF6F7 !important;
} */
.ContentMain .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title .iconfont {
    color: #28BBAC;
}

.ContentMain .ant-menu-inline-collapsed .ant-menu-item span:nth-child(4),
.ContentMain .ant-menu-inline-collapsed .ant-menu-submenu div span:nth-child(4) {
    display: none;
}

/* .ContentMain .ant-menu-item-selected{
    background-color: #EDF6F7 !important;
}
.ContentMain .ant-menu-item-selected span{
    color: #333333 !important;
} */
.ContentMain .teacherMenu>li span {
    font-size: 14px;
}

.ContentMain .site-layout {
    background-color: #EDF6F7;
    position: relative;
    margin-left: 260px;
}

.ContentMain .site-layout.collapsed {
    margin-left: 100px;
}

.ContentMain .portrait {
    color: #F5F5F5;
    text-align: center;
}

.ContentMain .site-layout-sub-header-background>div {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.ContentMain .site-layout-sub-header-background>div:first-child {
    min-width: 500px;
}

.ContentMain .site-layout-sub-header-background .header-title {
    margin-left: 40px;
    display: flex;
    flex-wrap: wrap;
    line-height: normal;
}

.ContentMain .site-layout-sub-header-background .header-title a {
    font-size: 16px;
    color: #333;
    margin-right: 24px;

}

.ContentMain .site-layout-sub-header-background .header-title a:hover {
    color: #28BBAC;
}

.ContentMain .header-left {
    display: flex;
    padding-left: 24px;
}

.ContentMain .header-right {
    display: flex;
    padding-right: 24px;
    min-width: 150px;
}

.ContentMain .user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ContentMain .user .photo {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #fff;
    overflow: hidden;
    position: relative;
    background-color: white;
    display: flex;
    margin-right: 10px;
}

.ContentMain .user .photo img {
    width: 100%;
    height: 100%;
}

.ContentMain .user .user-name {
    display: flex;
}

.ContentMain .user .user-name p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
    cursor: pointer;
    max-width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ContentMain .user .user-name .anticon-down {
    margin-left: 8px;
    display: flex;
    align-items: center;
}

.ContentMain .portrait p:nth-of-type(1) {
    margin-top: 15px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
}

.ContentMain .portrait p:nth-of-type(2) {
    margin-top: 5px;
    line-height: 12px;
    font-size: 12px;
}

.ContentMain .ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 20px rgba(100, 100, 100, 0.06);
    /* min-width: 1100px; */
}

.ContentMain .ant-breadcrumb {
    margin: 10px 24px !important;
}

.ContentMain .ant-layout-content {
    z-index: 1;
    /* overflow: auto; */
    position: relative;
    margin: 0 !important;
    /* padding: 0 24px; */
    /* padding-bottom: 44px; */
    /* height: calc(100vh - 188px); */
    /* 导航栏最宽180px， 屏幕最低适配 1280， 故设置1100px */
    /* min-width: 1100px; */
}

.ContentMain .ant-layout-content>div {
    margin: 0 24px;
}

.ContentMain .ant-layout-footer {
    /* position: absolute;
    padding: 0;
    left: 50%;
    bottom: 0; */
    z-index: 0;
    width: 100%;
    height: 44px;
    line-height: 46px;
    margin-left: 50%;
    transform: translateX(-50%);
    background-color: #EDF6F7;
    padding: 0;
    color: #999;
}

.ContentMain .logo,
.userControl {
    display: flex;
}

.ContentMain .logo {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0 30px 0;
    min-height: 81px;
}

.ContentMain .logo img {
    /* width: 162px; */
    height: 48px;
    padding: 0 10px;
    box-sizing: content-box;
}

.ContentMain .logo img.default_img {
    height: 48px;
}

/* .ContentMain .logo img:nth-child(1){
    width: auto;
    height: 100%;
} */
.ContentMain .userControl p {
    margin-right: 45px;
    color: #333333;
}

.ContentMain .userControl p:hover {
    color: #28BBAC;
}

/* .ContentMain .logo img:first-of-type{
    border-right: 2px solid #556E81;
} */
.iconfont {
    margin-right: 10px;
}

.ant-menu-submenu-title {
    font-size: 16px;
}

.site-layout-background {
    background: #EDF6F7;
    min-height: calc(100vh - 152px);
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip {
    display: none !important;
}


/* 小屏 */
@media (max-width: 1900px) {
    .ContentMain .ant-layout-sider {
        min-width: 180px !important;
        max-width: 180px !important;
        background-color: #28455B;
    }

    .ContentMain .ant-layout-sider.ant-layout-sider-collapsed {
        min-width: 100px !important;
        max-width: 100px !important;
    }

    .ContentMain .ant-menu-inline-collapsed.ant-menu,
    .ContentMain .ant-menu-vertical.ant-menu {
        width: 100px !important;
    }

    .ContentMain .site-layout {
        margin-left: 180px;
    }

    .ContentMain .site-layout.collapsed {
        margin-left: 100px;
    }

    /* 导航展开清空下一级菜单图标位置 */
    .ContentMain .teacherMenu .icon {
        left: 12px;
    }

    /* 导航折叠情况下一级菜单图标位置 */
    .ContentMain .teacherMenu .ant-menu-inline-collapsed.ant-menu .icon {
        left: 14px;
    }

    /* 一级菜单 */
    .ContentMain .teacherMenu .menu-item-content {
        padding-left: 46px;
        left: 22px
    }

    /* 二级菜单 */
    .ContentMain .teacherMenu .ant-menu-sub .menu-item-content {
        padding-left: 32px;
        left: 38px;
    }

    /* 展开清空下右侧箭头图标 */
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
        right: 30px;
    }

}